import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { updateCustomerDetails, getIndustryList, getSectorList } from 'actions/actions';
import { FormGroup } from 'reactstrap';
import styled from 'styled-components';

import Form from 'components/Common/Form/Form';
import Header from 'components/Common/Layout/Header';
import Navigation from 'components/Common/Layout/Navigation';
import Input from 'components/Common/Form/Input';
import Label from 'components/Common/Form/Label';

const StyledFormGroup = styled(FormGroup)`
  margin-bottom: 32px;
`;

export default React.memo(() => {
  const dispatch = useDispatch();
  const { accessToken, isMobile } = useSelector(state => state.reducer);
  const personalInfo = useSelector(state => state.reducer.personalInfo);
  const industryList = useSelector(state => state.reducer.industryList);

  const [state, setState] = useState({
    industry: personalInfo.employment ? personalInfo.employment.industry || '' : '',    
    sector: personalInfo.employment ? personalInfo.employment.nocCode && personalInfo.employment.occupation ? `${personalInfo.employment.nocCode}|${personalInfo.employment.occupation}` : '' : '',
    jobTitle: personalInfo.employment ? personalInfo.employment.jobTitle || '' : '',
    employerName: personalInfo.employment ? personalInfo.employment.employerName || '' : '',
    employerAddress: personalInfo.employment ? personalInfo.employment.employerAddress || '' : ''
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    const re = /^[a-zA-Z0-9 ,.-]+$/;
    if(name === 'industry') {
      dispatch(getSectorList(value, accessToken, isMobile));
      setState(prevState => ({ ...prevState, [name]: value }));
      setState(prevState => ({ ...prevState, sector: '' }));
    }
    else if(name === 'sector') {
      setState(prevState => ({ ...prevState, [name]: value }));
    }
    else {
      if(value === '' || re.test(value)) {
        setState(prevState => ({ ...prevState, [name]: value }));
      }
    }
  }

  const saveCustomerDetails = () => {
    const sector = state.sector.split('|');

    dispatch(updateCustomerDetails(
      {
        employment: {
          industry: state.industry,          
          nocCode: sector.length === 2 ? sector[0] : null,
          occupation: sector.length === 2 ? sector[1] : null,
          jobTitle: state.jobTitle,
          employerName: state.employerName,
          employerAddress: state.employerAddress
        },
      }
    ));
  }

  const submit = (e) => {
    e.preventDefault();
    if(canContinue) {
      saveCustomerDetails();
      navigate('/summary', { replace: true });
    }
  }

  const back = () => {
    saveCustomerDetails();
    navigate('/eligibility', { replace: true });
  }

  useEffect(() => {
    if(!industryList) {
      dispatch(getIndustryList(accessToken, isMobile));
    }
  }, []);

  const sectorList = industryList && state.industry ? industryList.find(industry => industry.Key === state.industry).sectors : null;

  const canContinue = (state.industry && state.sector && state.jobTitle && state.employerName && state.employerAddress);

  return (
    <Form>
      <Header>Your employment details</Header>
      <StyledFormGroup>
        <Label name="industry">Industry</Label>
        <Input type="select" name="industry" value={state.industry} onChange={handleChange}>
          <option value="" disabled>Please select one</option>
          {industryList ? industryList.map((industry, i) => (
            <option key={i} value={industry.Key}>{industry.Value}</option>
          )) : null}
        </Input>
      </StyledFormGroup>
      <StyledFormGroup>
        <Label name="sector">Sector</Label>
        <Input type="select" name="sector" value={state.sector} onChange={handleChange} disabled={!sectorList ? true : null}>
          <option value="" disabled>Please select one</option>
          {sectorList ? sectorList.map((sector, i) => (
            <option key={i} value={`${sector.Key}|${sector.Value}`}>{sector.Value}</option>
          )) : null}
        </Input>
      </StyledFormGroup>
      <StyledFormGroup>
        <Label name="jobTitle">Job title</Label>
        <Input type="text" name="jobTitle" onChange={handleChange} value={state.jobTitle} maxLength="35" />  
      </StyledFormGroup>
      <StyledFormGroup>
        <Label name="employerName">Employer name</Label>
        <Input type="text" name="employerName" onChange={handleChange} value={state.employerName} maxLength="35" />  
      </StyledFormGroup>
      <StyledFormGroup>
        <Label name="employerAddress">Employer address</Label>
        <Input type="text" name="employerAddress" onChange={handleChange} value={state.employerAddress} maxLength="35" />  
      </StyledFormGroup>
      <Navigation buttonLabel="Continue" onClickForward={submit} onClickBack={back} disabled={!canContinue} />
    </Form>);
});